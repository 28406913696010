.MuiTab-textColorPrimary {
    color: #fff !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: tomato !important;
}
.css-1aquho2-MuiTabs-indicator {
    background-color: tomato !important;
}
.homeSreen {
    height: 100%;
    background-color: #111;
}
.movie-group {
    background-color: #111;
}
.btn-more {
    width: 10rem;
    height: 3rem;
    border: solid 2px #f26b38;
    background-color: #111;
    color: #f26b38;
    cursor: pointer;
    position: absolute;
    right: 5rem;
    bottom: 0;
    margin-bottom: 1.5rem;
    z-index: 9;
    transition: 0.3s;
}
.btn-more:hover {
    background-color: #f26b38;
    color: #fff;
}
