.row {
    color: #fff;
    margin-left: 20px;
}
.row_posters {
    padding: 28px;
}

.row_posters::-webkit-scrollbar {
    display: none;
}
.item_poster {
    padding: 2rem;
}
.item_poster:hover h1 {
    text-shadow: 0.2rem 0.2rem 1rem #fab025;
    transform: scale(1.09);
    opacity: 1;
}
.row_poster {
    object-fit: contain;
    margin-right: 10px;
    width: 100%;
    transition: transform 450ms;
}
.item_poster h1 {
    margin-top: 1rem;
}
.item_poster:hover .row_poster {
    transform: scale(1.08);
    opacity: 1;
}
.row_posterLarge {
    max-height: 250px;
}
.item_poster:hover .row_posterLarge {
    transform: scale(1.09);
    opacity: 1;
}
