.item_querry h1 {
    margin-top: 5rem;
}

.item_querry img {
    width: 10rem;
    margin: 1rem;
}
.item_querry a {
    width: 98%;
    height: 20rem;
    display: inline-flex;
    border: solid 1px #f26b38;
    margin: 1rem;
}
