* {
    box-sizing: border-box;
    text-decoration: none;
}
.App {
    max-width: 1920px;
    min-width: 1368px;
    background-color: #111;
    height: auto;
}
h1 {
    color: #fff;
}
