.profile {
    height: 100vh;
    color: white;
}
.profile_body {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    margin-bottom: 1rem;
    max-width: 800px;
}
.profile_body > h1 {
    font-size: 60px;
    font-weight: 400;
    border-bottom: 1px solid #282c2d;
    margin-bottom: 20px;
}
.profile_info {
    display: flex;
}
.profile_info > img {
    height: 100px;
}
.profile_detail {
    color: white;
    margin-left: 25px;
    flex: 1;
}
.profile_detail > h2 {
    text-align: center;
    background-color: gray;
    padding: 15px;
    font-size: 1.5rem;
    padding-left: 20px;
}
.profile_plans > h3 {
    border-bottom: 1px solid #282c2d;
    padding-bottom: 10px;
}
.profile_signOut {
    padding: 10px 20px;
    font-size: 1rem;
    margin-top: 5%;
    width: 100%;
    color: #fff;
    background-color: #f26b38;
    font-weight: 600;
    border: none;
    cursor: pointer;
}
.form-profile {
    margin-top: 1rem;
    /* background-color: #fff; */
}

form .item-profile {
    padding-top: 1rem;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #f26b38 !important;
    border-width: 2px;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #3ea6ff !important;
    border-width: 2px;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: #f26b38 !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    color: #f26b38 !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #f26b38 !important;
}
.form-profile input {
    color: #fff;
}
.css-1wc848c-MuiFormHelperText-root.Mui-error {
    color: red !important;
}
