.detail-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.backdrop {
    position: relative;
    width: 100%;
}
.backdrop ::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.85);
    background-size: cover;
    z-index: 1;
}
.detail-content {
    display: inline-flex;
    position: absolute;
    width: 100%;
    padding: 7rem;
    z-index: 1;
    top: 0;
}
.poster {
    width: 30%;
    height: auto;
}
.content {
    margin: 5rem;
    width: 100%;
    color: #fff;
}
.content h1 {
    margin: 2rem 0;
}
h5,
a {
    color: #fff;
    text-decoration: none;
}
a:hover {
    color: #fff;
    font-weight: 700;
}
.recommend {
    width: 100%;
    background-color: #fff;
    padding: 1rem;
    margin-top: 1rem;
}
.recommend h2 {
    color: #111;
}
.recommend_item img {
    width: 12rem;
}
.recommend_item a {
    display: inline-block;
    margin-left: 1.5rem;
}
.recommend_item {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
}
.reccomend-title {
    width: 10rem;
    margin: 0.5rem 0;
}
.recommend_item span {
    color: #111;
}
.booking {
    cursor: pointer;
    font-size: 2rem;
    padding: 0 1rem;
    background-color: #f26b38;
}
.booking:disabled {
    cursor: not-allowed;
}
