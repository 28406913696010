.nav {
    top: 0;
    width: 100%;
    height: 130px;
    z-index: 2;
    transition-timing-function: ease-in;
    transition: all 0.5s;
}
.nav_contain {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
}

.nav_logo {
    display: block;

    width: 350px;
    object-fit: contain;
    padding-left: 20px;
    cursor: pointer;
}

.nav_user {
    display: block;
    margin-right: 20px;
}

.nav_black {
    position: fixed;
    background-color: #111;
    transition-timing-function: ease-in;
    transition: all 0.5s;
}
.nav_white {
    position: relative;
    background-image: linear-gradient(to bottom, #111 10%, rgba(255, 255, 255, 0.1) 90%);
    transition: all 0.5s;
}
.drdw-cate {
    display: block;
    width: 14%;
    height: fit-content;
    border-right: solid 1px #f26b38;
}
.search {
    position: relative;
    margin-left: 5rem;
    margin-top: 3.5rem;
}
.search input {
    width: 20rem;
    border: solid 2px #f26b38;
}
.search input:focus {
    outline: none;
    border: solid 2px #f26b38;
}
.search button {
    position: absolute;
    right: 0;
    z-index: 2;
    outline: none;
    border: solid 2px #f26b38;
}
