@font-face {
    font-family: "fontPacifico";
    src: url("../fonts/Pacifico-Regular.ttf");
}

.bookingMovie {
    font-family: "fontPacifico";
    background-color: #000;
}

.ghe {
    color: #000;
    border: 3px solid orange;
    width: 45px;
    height: 35px;
    font-size: 12px;
    font-weight: bolder;
    margin-left: 1%;
    /* margin-bottom: 1%; */
    border-radius: 5px;
}

.rowNumber {
    /* font-size: 25px; */
    width: 45px;
    height: 35px;
    background: none;
    border: none;
    margin-left: 1%;
    /* margin-bottom: 1%; */
    font-size: 30px;
    color: yellow;
}
.firstChar {
    color: yellow;
    font-size: 35px;
    margin-right: 15px;
}

.screen {
    border-bottom: 50px solid rgb(255, 159, 95);
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    height: 100;
    width: 80%;
    filter: drop-shadow(4px 30px 20px rgba(255, 255, 255, 0.5));
    font-size: 25px;
    color: #fff;
}

.gheDuocChon {
    border: 3px solid orange;

    background: orange;
    color: #000;

    cursor: no-drop !important;
    border-radius: 5px;
    width: 45px;
    height: 35px;
}

.gheDangChon {
    background: rgb(109, 238, 109);
    border: 3px solid rgb(109, 238, 109);
    color: #000;

    cursor: pointer;
    border-radius: 5px;
    width: 45px;
    height: 35px;
}

.text-warning button {
    padding: 0 5px;
    background-color: #28333e;
}
