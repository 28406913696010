.banner {
    position: relative;
    height: 500px;
    color: white;
    object-fit: contain;
}
header::before {
    content: "";
    position: absolute;
    background-image: linear-gradient(180deg, transparent, rgba(37, 37, 37, 0.3), #111);
    background-size: cover;
    width: 100%;
    height: 500px;
}
.banner_contents {
    position: absolute;
    margin-left: 30px;
    padding-top: 140px;
    height: 200px;
    z-index: 1;
}
.banner_title {
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0.3rem;
}
.banner_description {
    width: 45rem;
    line-height: 1.3;
    padding-top: 1rem;
    font-size: 0.8rem;
    max-width: 360px;
    height: 80px;
}

.banner_fadeBottom {
    background-image: linear-gradient(180deg, transparent, rgba(37, 37, 37, 0.7), #111);
}
.banner_button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    margin-right: 1rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding: 0.5rem 2rem;
}

.banner_button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.2s;
}
